import React, { useEffect } from "react";
import "./App.css";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import CompanyPage from "./pages/CompanyPage";
import ContactPage from "./pages/ContactPage";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PageContainer from "./components/ui/PageContainer/PageContainer";
import AgreementPage from "./pages/AgreementPage";

function App() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header />
            <PageContainer>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/products" element={<ProductPage />} />
                    <Route path="/company" element={<CompanyPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/agreement" element={<AgreementPage />} />
                </Routes>
            </PageContainer>
            <Footer />
        </>
    );
}

export default App;
