import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import prod1 from "../../assets/images/products/prod1.svg";
import prod2 from "../../assets/images/products/prod2.svg";
import prod3 from "../../assets/images/products/prod3.svg";
import { useLocation } from "react-router-dom";

const Products = () => {
    const currentPath = useLocation();
    const title = "Products";
    const label = "Products";

    const productsList = [
        {
            title: "Header bidding",
            blockTitle: "Maximizing Profit",
            description:
                "Our Header Bidding allows publishers to simultaneously reach out to multiple ad networks or exchanges. The system receives competitive bids and selects the most advantageous offer for ad placement, maximizing revenue.",
            image: prod1,
        },
        {
            title: "Video Widget",
            blockTitle: "VideoWidget",
            description:
                "An innovative product that enhances user engagement by showcasing video clips from your articles or YouTube channel, while simultaneously monetizing this content for additional revenue.",
            image: prod2,
        },
        {
            title: "Reс Widget",
            blockTitle: "ReсWidget",
            description: "Recwidget - a proprietary development that allows you to display the most relevant articles from your website to users.",
            image: prod3,
        },
    ];

    return (
        <div className={`${currentPath.pathname === "/products" ? "mt-[92px]" : ""}`}>
            <ComponentContainer separator>
                <TitleContainer title={title} label={label} color="#FFFFFF" />
                {currentPath.pathname !== "/products" &&
                    productsList.map((product, index) => (
                        <div className="flex flex-col w-full gap-[24px] md:gap-[40px] lg:gap-[40px]" key={index}>
                            <h2 className="leading-[44px] text-[24px] md:text-[32px] lg:text-[32px] ">
                                {" "}
                                <span className="text-white">{product.title.split(" ")[0]}</span> <span className="text-[#BDBDBD]">{product.title.split(" ")[1]}</span>
                            </h2>
                            <div className={`flex flex-col-reverse ${index % 2 === 0 ? "md:flex-row lg:flex-row" : "md:flex-row-reverse lg:flex-row-reverse"} gap-[24px] justify-between w-auto`}>
                                <div className="flex-1 flex-col gap-[24px] rounded-[22px] bg-white bg-opacity-5 h-fit max-h-none">
                                    <h3 className="p-[32px] pb-0 mb-[24px] text-[20px] text-white">{product.blockTitle}</h3>
                                    <p className="p-[32px] pt-0 text-[16px] text-[#BDBDBD]">{product.description}</p>
                                </div>
                                <div className="flex-1">
                                    <img src={product.image} className="ImgDrag w-full max-w-full" />
                                </div>
                            </div>
                        </div>
                    ))}
                {currentPath.pathname === "/products" &&
                    ProductsListFull.map((product, index) => (
                        <div className="flex flex-col w-full gap-[24px] md:gap-[40px] lg:gap-[40px]" key={index}>
                            <h2 className="leading-[44px] text-[24px] md:text-[32px] lg:text-[32px] ">
                                {" "}
                                <span className="text-white">{product.title.split(" ")[0]}</span> <span className="text-[#BDBDBD]">{product.title.split(" ")[1]}</span>
                            </h2>
                            <div className={`flex flex-col-reverse ${index % 2 === 0 ? "md:flex-row-reverse lg:flex-row-reverse" : "md:flex-row lg:flex-row"} gap-[24px] justify-between w-auto`}>
                                <div className="flex-1 justify-between h-auto gap-[24px]">
                                    {product.blockTitle.map((title, titleIndex) => (
                                        <div key={titleIndex} className={`rounded-[22px] bg-white bg-opacity-5 p-[32px] h-auto ${titleIndex !== product.blockTitle.length - 1 ? "mb-[24px]" : ""}`}>
                                            {title !== "" && <h3 className="mb-[24px] text-[20px] text-white">{title}</h3>}
                                            <p className="text-[16px] text-[#BDBDBD]">{product.description[titleIndex]}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex-1">
                                    <img src={product.image} className="ImgDrag w-full max-w-full" />
                                </div>
                            </div>
                        </div>
                    ))}
            </ComponentContainer>
        </div>
    );
};

export default Products;

const ProductsListFull = [
    {
        title: "Header bidding",
        blockTitle: ["Maximizing Profit", "Improved Efficiency"],
        description: [
            "Our Header Bidding allows publishers to simultaneously reach out to multiple ad networks or exchanges. The system receives competitive bids and selects the most advantageous offer for ad placement, maximizing revenue.",
            "Header Bidding enables real-time auctioning of ad space on a website, leading to more efficient allocation of advertising opportunities. This increases the likelihood of publishers receiving optimal bids and achieving better sales results.",
        ],
        image: prod1,
    },
    {
        title: "Video Widget",
        blockTitle: ["", "", ""],
        description: [
            "Video widget is an innovative product that allows you to enhance user engagement by including video clips from your articles or YouTube channel. It is an attractive means of presenting content that increases interest and captures the attention of visitors, while also opening up new opportunities for monetizing your video content.",
            "The video widget helps create a visually appealing experience by offering users the ability to watch video clips that align with your articles or the content of your YouTube channel. This not only boosts engagement and interest in your content but also provides additional revenue streams by monetizing your video content through advertising or other means.",
            "With the video widget, you can significantly amplify the impact of your materials, attract more users, and simultaneously leverage video content for revenue generation. It is a powerful tool that helps make your website or platform more interactive and successful in terms of user interaction and monetization.",
        ],
        image: prod2,
    },
    {
        title: "Reс Widget",
        blockTitle: ["", "", ""],
        description: [
            "Recwidget - a proprietary development that allows you to display the most relevant articles from your website to users.",
            "The news recommendation algorithm, based on a scoring system, ensures a high degree of relevance and displays maximally relevant content.",
            "Thanks to this widget, the depth of page views increases, and native advertising inserts provide an opportunity to effectively monetize the content.",
        ],
        image: prod3,
    },
];
