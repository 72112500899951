import React, { useState, ChangeEvent, FormEvent } from "react";

type FormData = {
    name: string;
    email: string;
    website: string;
    phoneNumber: string;
};

const ContactForm: React.FC = () => {
    const initialFormData: FormData = {
        name: "",
        email: "",
        website: "",
        phoneNumber: "",
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);

    const [errors, setErrors] = useState<Partial<FormData>>({});

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newErrors: Partial<FormData> = {};

        if (!formData.name) {
            newErrors.name = "Name is required";
        }
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!isValidEmail(formData.email)) {
            newErrors.email = "Invalid email format";
        }
        if (!formData.website) {
            newErrors.website = "Website is required";
        }
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = "Phone number is required";
        } else if (!isValidPhoneNumber(formData.phoneNumber)) {
            newErrors.phoneNumber = "Invalid phone number format";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            console.log("Form data submitted:", formData);
            setFormData({
                name: "",
                email: "",
                website: "",
                phoneNumber: "",
            });

            setErrors({});
        }
    };

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phoneNumber: string) => {
        const phoneRegex = /^[\d()+-]+$/;
        return phoneRegex.test(phoneNumber);
    };

    return (
        <div className="">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="font-sans text-white pl-[20px] h-[60px] bg-[#333133] rounded-[12px] placeholder-[rgba(255,255,255,0.5)]"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <span className="pl-[20px] text-red-500 bg">{errors.name}</span>}
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        className="mt-[16px] text-white pl-[20px] h-[60px] bg-[#333133] rounded-[12px] placeholder-[rgba(255,255,255,0.5)]"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <span className="pl-[20px] text-red-500">{errors.email}</span>}
                    <input
                        type="text"
                        name="website"
                        placeholder="Website"
                        className="mt-[16px] text-white pl-[20px] h-[60px] bg-[#333133] rounded-[12px] placeholder-[rgba(255,255,255,0.5)]"
                        value={formData.website}
                        onChange={handleChange}
                    />
                    {errors.website && <span className="pl-[20px] text-red-500">{errors.website}</span>}
                    <input
                        type="text"
                        name="phoneNumber"
                        placeholder="+65 1234 5678"
                        className="mt-[16px] text-white pl-[20px] h-[60px] bg-[#333133] rounded-[12px] placeholder-[rgba(255,255,255,0.5)]"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                    {errors.phoneNumber && <span className="pl-[20px] text-red-500">{errors.phoneNumber}</span>}
                </div>

                <button
                    type="submit"
                    className="lg:mr-[32px] bg-blue-500 hover-bg-blue-600 mt-[40px] text-[16px] text-white h-[60px] rounded-[12px] flex items-center justify-center w-full md:w-2/4 lg:w-2/4"
                >
                    <span>Send</span>
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
