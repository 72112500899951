import { Heading } from "@radix-ui/themes";
import React from "react";

type ImageCardProps = {
    text: {
        title: string;
        text2: string | string[];
        text3: string;
    };
    image: string;
};

const ImageCard: React.FC<ImageCardProps> = ({ text, image }) => {
    const phoneNumbers = text.text3.split(" / ");
    return (
        <div className="text-white min-w-[260px] flex flex-col justify-start gap-[10px]">
            <img
                src={image}
                width="260px"
                height="160px"
                alt={text.title}
                className="ImgDrag max-h-[160px] rounded-[12px]"
            />
            <Heading style={{ margin: "0", padding: "0px", fontSize: "24px" }}>
                {text.title}
            </Heading>
            <h3 className="w-[260px] max-w-[260px] text-[16px] text-[#BDBDBD]">
                {Array.isArray(text.text2) ? (
                    <>
                        {text.text2[0]} <br /> {text.text2[1]}
                    </>
                ) : (
                    text.text2
                )}
            </h3>
            <div className="w-[260px] max-w-[260px] text-[16px] text-[#BDBDBD]">
                <a href={`tel:${phoneNumbers[0]}`}>{phoneNumbers[0]}</a>{" "}
                {phoneNumbers.length > 1 ? "/ " : ""}
                <a href={`tel:${phoneNumbers[1]}`}>{phoneNumbers[1]}</a>
            </div>
        </div>
    );
};

export default ImageCard;
