import React from "react";
import { Flex } from "@radix-ui/themes";
import "../../../App.css";

type TitleContainerProps = {
    title: string;
    label: string;
    color?: string;
    fontSize?: string;
};

const TitleContainer: React.FC<TitleContainerProps> = ({ title, label, color, fontSize }) => {
    const words = title.split(" ");
    const firstWord = words[0];
    const restWords = words.slice(1).join(" ");
    return (
        <Flex className="w-full justify-between">
            <h2 style={{ color: color, fontSize: fontSize }} className={`title md:leading-[70px] lg:leading-[70px] text-[32px] md:text-[48px] lg:text-[56px] text-[${color}]`}>
                {firstWord} <span style={{ color: color === "#FFFFFF" ? "#BDBDBD" : color }}>{restWords}</span>
            </h2>
            {label !== "" && (
                <span className="select-none invisible h-[24px] rounded-full md:visible lg:visible lg:h-[24px] md:h-[24px] border border-white opacity-25 lg:border-opacity-25 md:border-opacity-25 text-white lg:text-[14px] md:text-[14px] lg:leading-[18px] md:leading-[18px] lg:px-[3px] md:px-[3px] lg:py-[3px] md:py-[3px] lg:pr-[24px] md:pr-[24px] lg:pl-[24px] md:pl-[24px] flex items-center justify-center">
                    {label.toUpperCase()}
                </span>
            )}
        </Flex>
    );
};

export default TitleContainer;
