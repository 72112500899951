import { Separator } from "@radix-ui/themes";
import React from "react";
import "../../../App.css";

type ComponentContainerProps = {
    separator: boolean;
    children: React.ReactNode;
};
const ComponentContainer: React.FC<ComponentContainerProps> = ({
    separator,
    children,
}) => {
    return (
        <div className="w-full px-[24px] md:px-[48px] lg:px-[140px] z-2">
            {separator === true ? (
                <div className="flex flex-col mt-[24px] md:mt-[48px] lg:mt-[48px] gap-[24px] md:gap-[40px] lg:gap-[40px] ">
                    {children}
                    <Separator
                        orientation="horizontal"
                        size="4"
                        className="bg-[#3E3C3E] lg:mt-[8px] md:mt-[8px]"
                    />
                </div>
            ) : (
                children
            )}
        </div>
    );
};

export default ComponentContainer;
