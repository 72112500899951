import React from "react";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import { Text } from "@radix-ui/themes";
import ContactForm from "../ContactForm/ContactForm";
const HowItWorks = () => {
    const title = "How It Works";
    const label = "Works";

    const worksList = [
        {
            num: "01",
            title: "Consultation",
            description: "We provide professional consultations where we attentively listen to your needs and goals in order to fully understand how we can assist you.",
        },
        {
            num: "02",
            title: "Website Analysis",
            description:
                "We meticulously analyze the capabilities of your website, its technology, and flexibility to determine the most effective solutions for its improvement in terms of monetization and user experience.",
        },
        {
            num: "03",
            title: "Mockup Development",
            description:
                "Our team creates website mockups, taking into account your preferences and our recommendations. We pay special attention to integrating the proposed products to ensure their optimal visual and functional integration.",
        },
        {
            num: "04",
            title: "Implementation and Analysis",
            description:
                "Collaborating with your web/app developers, we implement the designed mockup, ensuring its proper functioning. After the launch, we carefully analyze the results and metrics to ensure the effectiveness and successful operation of the website and proposed products.",
        },
    ];
    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="#FFFFFF" />
            <div className="flex flex-col md:flex-row lg:flex-row justify-between text-white">
                <div style={{ gap: "24px", position: "relative" }} className="w-full flex md:w-2/4 lg:w-2/4 flex-col">
                    {worksList.map((work, index) => (
                        <div
                            key={index}
                            style={{
                                minWidth: "100%",
                                borderRadius: "22px",
                                background: "rgba(255, 255, 255, 0.05)",
                                padding: "32px",
                                gap: "32px",
                                display: "flex",
                            }}
                            className="flex-col md:flex-row lg:flex-row "
                        >
                            <div
                                style={{
                                    display: "flex",
                                    minWidth: "160px",
                                }}
                                className="flex-row lg:flex-col justify-start items-center md:items-start lg:items-start md:justify-between"
                            >
                                <span className="select-none flex items-center justify-center w-[48px] h-[26px] rounded-full bg-[#484648] text-[14px] ">{work.num}</span>
                                <Text className="text-[20px] pl-[12px] md:pl-0 lg:p-0">{work.title}</Text>
                            </div>
                            <Text className="text-[#BDBDBD]">{work.description}</Text>
                        </div>
                    ))}
                </div>
                <div className="relative pt-[24px] md:pt-[0px] lg:pt-[0px] pl-[0px] md:pl-[80px] lg:pl-[80px] w-full md:w-2/4 lg:w-2/4">
                    <div className="sticky top-[108px]">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </ComponentContainer>
    );
};

export default HowItWorks;
