import React from "react";

type PageContainerProps = {
    children: React.ReactNode;
};
const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
    return (
        <main className={"flex flex-col"}>
            {children}
            <div className="first-background"></div>
            <div className="second-background"></div>
        </main>
    );
};

export default PageContainer;
