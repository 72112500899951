import React from "react";
import Products from "../components/Products/Products";
import Dashboard from "../components/Dashboard/Dashboard";
const ProductPage = () => {
    return (
        <>
            <Products />
            <Dashboard />
        </>
    );
};

export default ProductPage;
