import React from "react";

type IconContainerProps = {
    icon: string;
};

const IconContainer: React.FC<IconContainerProps> = ({ icon }) => {
    return (
        <div className="rounded-[22px] w-[200px] h-[100px] flex items-center justify-center bg-opacity-5 bg-white">
            <img src={icon} alt="icon" className="ImgDrag" />
        </div>
    );
};

export default IconContainer;
