import React from "react";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";

const Footer = () => {
    return (
        <footer>
            <ComponentContainer separator={false}>
                <div className="md:flex md:flex-row justify-between md:px-[24px] lg:px-[24px] py-[24px] md:py-[16px]">
                    <div className="flex flex-row justify-between md:flex-col gap-[16px] text-left mb-[16px] md:mb-0 lg:mb-0">
                        <p className="text-[rgba(255,255,255,0.65)] leading-[18px] ">
                            TDS Bid PTE LTD
                        </p>
                        <p className="text-[rgba(255,255,255,0.65)] leading-[18px]">
                            Singapore, Bukit Batok, 23
                        </p>
                    </div>
                    <div className="flex flex-row justify-between md:flex-col md:text-center lg:text-center mb-[16px] md:mb-0 lg:mb-0">
                        <p className="text-[rgba(255,255,255,0.65)] leading-[18px]">
                            © 2023. All rights reserved
                        </p>
                        <p className="text-[rgba(255,255,255,0.65)] leading-[18px]">
                            Privacy Statement
                        </p>
                    </div>
                    <div className="flex flex-row justify-between md:flex-col text-right ">
                        <a
                            href="mailto:support@tds.bid?subject = Support&body = Message"
                            className="text-[rgba(255,255,255,0.65)] leading-[18px]"
                        >
                            support@tds.bid
                        </a>
                        <a
                            href="tel:+6583387195"
                            className="text-[rgba(255,255,255,0.65)] leading-[18px]"
                        >
                            +65 8338 7195
                        </a>
                    </div>
                </div>
            </ComponentContainer>
        </footer>
    );
};
export default Footer;
