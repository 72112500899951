import React, { forwardRef } from "react";
import ContactForm from "../ContactForm/ContactForm";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import singaporeBg from "../../assets/images/singapore-bg.webp";

const ConnectWithUs = forwardRef<HTMLDivElement | null, {}>((props, ref) => {
    const title = "Connect with us";
    const label = "";
    const componentStyle = {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray url(${singaporeBg}) 100% / cover no-repeat`,
    };

    return (
        <div ref={ref} style={componentStyle}>
            <ComponentContainer separator>
                <TitleContainer title={title} label={label} color="#333133" />
                <div className="w-full md:w-2/4 lg:w-2/4 md:pr-80px lg:pr-80px bg-black bg-opacity-50 rounded-[12px] p-4 ">
                    <ContactForm />
                </div>
            </ComponentContainer>
        </div>
    );
});

export default ConnectWithUs;
