import React from "react";
import Leadership from "../components/Leadership/Leadership";
import image from "../assets/images/companyimg.webp";
import ComponentContainer from "../components/ui/ComponentContainer/ComponentContainer";
import TitleContainer from "../components/ui/TitleContainer/TitleContainer";

const CompanyPage = () => {
    return (
        <>
            <Mission />
            <Leadership />
        </>
    );
};

export default CompanyPage;

const Mission = () => {
    const heading1 = "Our Mission";
    const heading2 = "Our core values";
    const text1 =
        "We strive for openness and efficiency in everything we do, inside and outside of the company. We look for new opportunities, turn them into simple and understandable solutions, apply them and share them with society";
    const text2 =
        "We care. We are sincerely interested in helping Partners and the Company achieve their goals. We dive deep into the core of the tasks and are constantly improving in what we do.";
    const text3 =
        "We believe that «telling it like it is» is the easiest and most effective way to build long-term relationships with partners. We know how to ensure transparency of our work and our decisions without hiding behind bureaucracy or creating artificial barriers.";
    const text4 =
        "You can count on us. We keep our word and keep our promises.";
    const text5 =
        "We focus on finding the most effective solution, not on being right. We know how to hear, understand and ask the right questions.";

    return (
        <ComponentContainer separator>
            <div className="flex flex-col-reverse lg:flex-row md:flex-row mt-[96px] text-white">
                <div className="flex flex-col justify-center w-full md:w-2/4 lg:w-2/4 pr-[24px] mt-[24px] md:mt-[0] lg:mt-[0px]">
                    <TitleContainer title={heading1} color="#FFFFFF" label="" />
                    <p className="mt-[24px] md:mt-[48px] lg:mt-[48px] text-[16px] text-[#BDBDBD]">
                        {text1}
                    </p>
                </div>
                <img
                    src={image}
                    className="ImgDrag w-full md:w-2/4 lg:w-2/4 rounded-[22px]"
                />
            </div>
            <div className="text-white">
                <TitleContainer title={heading2} color="#FFFFFF" label="" />
                <div className="flex flex-wrap gap-[24px] w-full mt-[24px] md:mt-[48px] lg:mt-[48px]">
                    <div className="p-[32px] flex flex-col gap-[24px] w-full md:w-[calc(50%-12px)] lg:w-[calc(50%-12px)] rounded-[22px] bg-white bg-opacity-5">
                        <h3 className="text-[20px]">Engagement</h3>
                        <p className="text-[#BDBDBD] text-[16px]">{text2}</p>
                    </div>
                    <div className="p-[32px] flex flex-col gap-[24px] w-full md:w-[calc(50%-12px)] lg:w-[calc(50%-12px)] rounded-[22px] bg-white bg-opacity-5">
                        <h3 className="text-[20px]">Transparency</h3>
                        <p className="text-[#BDBDBD] text-[16px]">{text3}</p>
                    </div>
                    <div className="p-[32px] flex flex-col gap-[24px]  w-full md:w-[calc(50%-12px)] lg:w-[calc(50%-12px)] rounded-[22px] bg-white bg-opacity-5">
                        <h3 className="text-[20px]">Integrity</h3>
                        <p className="text-[#BDBDBD] text-[16px]">{text4}</p>
                    </div>
                    <div className="p-[32px] flex flex-col gap-[24px] w-full md:w-[calc(50%-12px)] lg:w-[calc(50%-12px)] rounded-[22px] bg-white bg-opacity-5">
                        <h3 className="text-[20px]">Sanity</h3>
                        <p className="text-[#BDBDBD] text-[16px]">{text5}</p>
                    </div>
                </div>
            </div>
        </ComponentContainer>
    );
};
