import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/icons/logo_TDS.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import { Flex } from "@radix-ui/themes";

const NavLink = ({
    to,
    label,
    currentPath,
}: {
    to: string;
    label: string;
    currentPath: string;
}) => {
    const isActive = currentPath === to;

    return (
        <Link
            to={to}
            className={`z-10 hover:text-white text-base font-normal ${
                isActive ? "text-white" : "text-gray-400"
            }`}
        >
            {label}
        </Link>
    );
};

function Header() {
    const currentPath = useLocation();

    return (
        <header className="bg-white bg-opacity-5 backdrop-blur-[10px] h-[84px] fixed w-full flex align-center z-10">
            <ComponentContainer separator={false}>
                <Flex className="flex flex-row justify-between h-full items-center">
                    <Link to="/">
                        <img src={Logo} alt="Logo" className="ImgDrag" />
                    </Link>
                    <div className="hidden md:visible md:flex flex-row justify-between items-center gap-12">
                        <nav className="flex gap-12">
                            <NavLink
                                to="/"
                                label="Home"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/agreement"
                                label="Agreement"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/products"
                                label="Products"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/company"
                                label="Company"
                                currentPath={currentPath.pathname}
                            />
                            <NavLink
                                to="/contact"
                                label="Contact"
                                currentPath={currentPath.pathname}
                            />
                        </nav>
                        <a
                            href="https://reports.tds.bid/login"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-[#538DFE] hover:bg-blue-600 text-white h-12 w-24 rounded-md text-sm flex items-center justify-center"
                        >
                            <span className="text-[16px]">Login</span>
                        </a>
                    </div>
                    <div className="visible md:hidden ">
                        <DropdownMenu />
                    </div>
                </Flex>
            </ComponentContainer>
        </header>
    );
}

export default Header;

const DropdownMenu = () => {
    const [openNav, setOpenNav] = useState(false);

    return (
        <DropdownMenuPrimitive.Root>
            <DropdownMenuPrimitive.Trigger asChild>
                <button
                    onClick={() => setOpenNav(!openNav)}
                    className="bg-blue-500 rounded-[12px] h-[48px] w-[48px] px-[12px] flex justify-center items-center"
                >
                    <img
                        src={MenuIcon}
                        className="text-white bg-blue w-[18px]"
                    />
                </button>
            </DropdownMenuPrimitive.Trigger>
            {openNav && (
                <DropdownMenuPrimitive.Portal>
                    <DropdownMenuPrimitive.Content
                        sideOffset={0}
                        alignOffset={0}
                        style={{
                            width: "132px",
                            marginTop: "22px",
                            marginRight: "24px",
                        }}
                        className={
                            "px-[32px] py-[24px] flex flex-col items-center gap-[24px] radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down z-10 bg-white bg-opacity-5 backdrop-blur-[20px]"
                        }
                    >
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none"
                        >
                            <Link
                                to="/"
                                className="text-white text-base font-normal "
                            >
                                <span>Home</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/products"
                                className="text-white text-base font-normal"
                            >
                                <span>Products</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/company"
                                className="text-white text-base font-normal"
                            >
                                <span>Company</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(!openNav)}
                            className="focus:outline-none "
                        >
                            <Link
                                to="/contact"
                                className="text-white text-base font-normal"
                            >
                                <span>Contact</span>
                            </Link>
                        </DropdownMenuPrimitive.Item>
                        <DropdownMenuPrimitive.Item
                            onClick={() => setOpenNav(false)}
                            className="focus:outline-none"
                        >
                            <a
                                href="https://reports.tds.bid/login"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-[#538DFE] rounded-[12px] hover:bg-blue-600 text-white h-[42px] w-[84px] rounded-[12px] text-sm flex items-center justify-center"
                            >
                                <span>Login</span>
                            </a>
                        </DropdownMenuPrimitive.Item>
                    </DropdownMenuPrimitive.Content>
                </DropdownMenuPrimitive.Portal>
            )}
        </DropdownMenuPrimitive.Root>
    );
};
