import React from "react";
import ComponentContainer from "../components/ui/ComponentContainer/ComponentContainer";
const AgreementPage = () => {
    return (
        <ComponentContainer separator>
            <div className="text-white font-[500] mt-[96px] flex flex-col gap-[16px]">
                <h2 className="text-[28px] leading-[34px]">
                    TDS.BID Pte №1 Offer. Advertising code
                </h2>
                <p className="text-[16px] leading-[22px] text-[#BDBDBD] ">
                    Republic of Singapore
                </p>
                <p className="text-[16px] leading-[22px] text-[#BDBDBD] ">
                    Version No.1
                </p>
                <p className="text-[16px] leading-[22px] text-[#BDBDBD] ">
                    Date of placement: 01.08.2022
                </p>
                <p className="text-[16px] leading-[22px] text-[#BDBDBD] ">
                    Date of entry into force: 01.08.2022
                </p>
                <h2 className="text-[20px] leading-[28px]">Preamble</h2>
                <p className="text-[16px] leading-[22px] text-[#BDBDBD]">
                    This document is an offer of the Singapore United Company
                    TDS.BID PTE.LTD, a legal entity established and operating in
                    accordance with the laws of the Republic of Singapore,
                    represented by the director Khaw Chiew Ling Alicia , acting
                    on the basis of a power of attorney no number dated June 23,
                    2022 (hereinafter referred to as text – “Company”), conclude
                    the Agreement for the advertising services.
                </p>
                <h2 className="text-[20px] leading-[28px] ">
                    1. Terms and Definitions
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3 className="text-[16px] leading-[22px]">
                        1.1. For the purposes of this document, the following
                        terms have the following meanings:
                    </h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <h3>
                            <span className="text-white">
                                Acceptance of the Offer
                            </span>{" "}
                            - is full and unconditional Acceptance of the Offer
                            by performing the actions provided for by the
                            provisions of this Offer and the documents to which
                            the Offer refers.
                        </h3>
                        <h3>
                            <span className="text-white">Resource Owner</span> -
                            is a capable individual or legal entity that accepts
                            the Offer and owns the Resources legally and/or has
                            the right to use the Resources in accordance with
                            the concluded Agreement.
                        </h3>
                        <h3>
                            <span className="text-white">Statistics data</span>{" "}
                            - is data and information in electronic form posted
                            in the Affiliate interface at: https://tds.bid
                            (Login), which are compiled and provided by the
                            Company to the Owner and contain information about
                            the number of clicks, displaying, cost of the
                            Advertisement, the amount of the Owner’s
                            remuneration and other parameters of interaction
                            between the Parties.
                        </h3>
                        <h3>
                            <span className="text-white">Agreement</span> – is
                            an agreement for the advertising services between
                            the Owner and the Company, which is concluded by
                            Accepting the Offer.
                        </h3>
                        <h3>
                            <span className="text-white">Company</span> – is
                            Singapore United Company TDS.BID PTE.LTD,
                            registration number{" "}
                            <span className="text-white">202221705E</span>,
                            address:{" "}
                            <span className="text-white">
                                659526, Singapore, 2021 Bukit Batok 23 #03-206
                            </span>
                            , account:
                            <span className="text-white">7709525682</span>{" "}
                            (USD), Bank:{" "}
                            <span className="text-white">UOB Singapore</span>,
                            SWIFT: <span className="text-white">UOVBSGSG</span>.
                        </h3>
                        <h3>
                            <span className="text-white">Personal Account</span>{" "}
                            – is the Supplier’s software interface designed for
                            remote interaction for the purpose of exchanging
                            information and sending notifications, but not
                            limited to the specified ones, which contains
                            Statistics Data, information about the Company
                            and/or the Owner, Resources, the amount of the
                            Owner’s remuneration, as well as other information
                            determined by Supplier.
                        </h3>
                        <h3>
                            <span className="text-white">Reporting period</span>{" "}
                            – is a period of time equal to one calendar month.
                        </h3>
                        <h3>
                            <span className="text-white">Offer</span> – is this
                            document posted on the Internet at
                            https://tds.bid/agreement , including all documents
                            referred to in it.
                        </h3>
                        <h3>
                            <span className="text-white">
                                Affiliate interface
                            </span>{" "}
                            – is a software interface available at:
                            https://tds.bid (Login), designed for remote
                            interaction of the Parties in order to fulfill the
                            obligations of the Parties under this Offer,
                            exchange information and send notifications, but not
                            limited to the above, which contains Statistics
                            data, information about the Owner, Resources, the
                            amount of the Owner’s remuneration, as well as other
                            information determined by the Company.
                        </h3>
                        <h3>
                            <span className="text-white">User</span> – is any
                            person who is a visitor to the Owner’s Resources.
                        </h3>
                        <h3>
                            <span className="text-white">Supplier</span> – is a
                            third party, including but not limited to Google,
                            Pubmatic, OpenX engaged by the Company to place
                            Advertisements on the Owner’s Resources and/or for
                            purposes related to the execution of the Offer. The
                            Supplier uses a set of software and hardware owned
                            and/or used by the Supplier, designed to interact
                            with the Resources, ensure the display of
                            Advertising on Advertising Spaces, as well as to
                            record Statistics Data.
                        </h3>
                        <h3>
                            <span className="text-white">Resource</span> – is
                            information resources on the Internet (including
                            versions for desktops, tablets and/or other mobile
                            devices), computer programs, applications for mobile
                            devices, other digital media, as well as their
                            content and constituent elements (information, files
                            and other data ), owned and/or managed by the Owner,
                            in which the Company places the Advertising Code for
                            the purposes of this Offer. In some cases, the
                            Resources, including their constituent elements, may
                            be provided by the Owner to Internet users using the
                            software and hardware of the Company.
                        </h3>
                        <h3>
                            <span className="text-white">Advertising</span> – is
                            a set of text, graphic, interactive and video
                            materials containing information falling under the
                            definition of “advertising” in accordance with
                            applicable law, containing advertising of third
                            parties (Providers) that are Service Companies
                            (Advertisers) under the agreements of Suppliers. The
                            Parties are aware that placement of Advertisements
                            to be displayed under this Agreement is carried out
                            by third parties through the Company’s Advertising
                            System. The Customer does not moderate the placed
                            Advertisement; moderation is carried out by the
                            Supplier on the following conditions:
                            <div className="mt-[16px]">
                                <span className="ml-[24px] text-white">
                                    Google
                                </span>{" "}
                                -{" "}
                                <a
                                    target={"_blank"}
                                    rel="noreferrer"
                                    href={
                                        "https://support.google.com/adspolicy/topic/6021648?hl=en&ref_topic=1626336&sjid=18280752957808078971-NA"
                                    }
                                >
                                    Advertising Policies
                                </a>
                            </div>
                            <div className="mt-[16px]">
                                <span className="ml-[24px] text-white">
                                    Pubmatic
                                </span>{" "}
                                -{" "}
                                <a
                                    target={"_blank"}
                                    rel="noreferrer"
                                    href={
                                        "https://pubmatic.com/legal/supply-policy"
                                    }
                                >
                                    Supply Policy
                                </a>
                            </div>
                            <div className="mt-[16px]">
                                <span className="ml-[24px] text-white">
                                    OpenX
                                </span>{" "}
                                -{" "}
                                <a
                                    target={"_blank"}
                                    rel="noreferrer"
                                    href={
                                        "https://www.openx.com/legal/ad-exchange-supply-policy/"
                                    }
                                >
                                    Supply Policy
                                </a>
                            </div>
                        </h3>
                        <h3>
                            <span className="text-white">Advertisers</span> – is
                            third parties who have entered into an agreement
                            with the Company and/or Suppliers or their
                            affiliates, the result of which is the placement of
                            Advertisements on the Owner’s resources.
                        </h3>
                        <h3>
                            <span className="text-white">Advertising code</span>{" "}
                            – is a sequence of characters in a programming
                            language created for the Company by the advertising
                            management system, which ensures the interaction of
                            the Resource and the advertising management system,
                            as a result of which Advertisements of Suppliers and
                            the Company are displayed on the Resource.
                        </h3>
                        <h3>
                            <span className="text-white">
                                Advertising space
                            </span>{" "}
                            – is a place allocated in the design (code) of the
                            corresponding Resource, and intended for displaying
                            Advertisements.
                        </h3>
                        <h3>
                            <span className="text-white">
                                Advertising system
                            </span>{" "}
                            – is the software used by the Company, which allows,
                            through interaction with the Owner’s Resource and
                            Suppliers, to manage the manner and process of
                            displaying (Display) Advertisements, as well as keep
                            records of the number of Displays of Advertisements,
                            types of Advertisements (Formats), time periods
                            during which Suppliers’ Displays of Advertisements,
                            Resources on which Advertisements were displayed,
                            and other parameters that allow obtaining the most
                            detailed information about the course of Displays of
                            Advertisements for the Advertiser/Company.
                        </h3>
                    </div>
                    <h3>
                        1.2. The Offer may use terms not defined in this
                        section. In this case, when interpreting such a term,
                        one should be guided by the interpretation of the term
                        defined: first of all - by the current legislation, then
                        - by the prevailing (common) one on the Internet.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    2. Subject of the Agreement
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex-col gap-[16px]">
                    <h3>
                        2.1. The subject of this Agreement is the provision of
                        the Company’s technical Services related to the
                        Company’s placement of Advertisements on Advertising
                        Spaces (hereinafter referred to as the “Services”) by
                        the Owner, on the Owner’s Resources, in particular:{" "}
                    </h3>
                    <div className="flex-col gap-[16px]">
                        2.1.1. installation of the Advertising Code (including
                        the SDK, if necessary) on the Owner’s Resources and
                        ensuring its operability and correct software and
                        hardware interaction with the Company’s system,
                        including interaction through the API; 2.1.2. ensuring
                        the display of Advertising Materials on Advertising
                        Spaces on the Owner’s Resources in compliance with the
                        requirements established by this Offer,
                    </div>
                    <h3>
                        2.2. Placement of the Advertising Code and/or display of
                        Advertising Materials by the Company in any other
                        technical way may be carried out on Advertising Spaces
                        on the Owner’s Resources (including their constituent
                        elements), which are provided by the Owner to Internet
                        users using the Company’s software and hardware,
                        including, but not limited to, when:
                    </h3>
                    2.2.1. The Company provides the Owner with the technical
                    ability to provide Internet users with access to the Owner’s
                    Resource (or its individual elements) through the Company’s
                    website, its components and services; or 2.2.2. The Owner’s
                    resource or its individual elements are placed on the
                    software and/or hardware platform provided by the Company;
                    or 2.2.3. The display of the Owner’s Resource or its
                    individual elements is carried out by technological export
                    of data, content, elements or other components of the
                    Owner’s Resource to the corresponding service or to the
                    corresponding website of the Company, both through technical
                    integration and in other ways.
                </div>

                <h2 className="text-[20px] leading-[28px]">
                    3. Execution Manner
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        3.1. Acceptance and compliance by the Owner with the
                        requirements and provisions stipulated by this Offer, as
                        well as the documents to which there is a reference in
                        the Offer, is a prerequisite for the provision of the
                        Services.
                    </h3>
                    <h3>
                        3.2. The Owner shall begin to provide Services to the
                        Company only after the cumulative fulfillment of the
                        following conditions by the Owner:
                    </h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            3.2.1. The Owner has registered as a user in the
                            Affiliate interface of the Company.
                        </p>
                        <p>
                            3.2.2. The Owner filled out the registration form
                            at: https://tds.bid/agreement in accordance with the
                            authentic data about himself: for individuals - full
                            name, surname, patronymic, registration address
                            (postal) address, full payment details, date of
                            birth, email address and mobile phone number; for
                            legal entities - the full company name of the
                            organization, legal and postal address, full name,
                            surname, patronymic of the person authorized to
                            conclude the Agreement on behalf of and in the
                            interests of such a legal entity; as well as the
                            technical data of the Owner’s Resources;
                        </p>
                        <p>
                            3.2.3. The Owner provided, at the request of the
                            Company, scanned copies of all pages of the
                            citizen’s passport issued to the Owner, or any other
                            document confirming the status of the Owner’s
                            resident in the state specified by the Owner in the
                            registration form / a scanned copy of the
                            certificate of registration of a legal entity or an
                            extract from the commercial register of the country
                            of which this Owner - a legal entity is a resident.
                        </p>
                        <p>
                            3.2.4. The Owner duly executed the Acceptance of the
                            Offer by ticking the box in the registration form.
                        </p>
                    </div>
                    <h3>
                        3.2. The Owner shall begin to provide Services to the
                        Company only after the cumulative fulfillment of the
                        following conditions by the Owner:
                    </h3>
                    <h3>3.3. The Owner warrants that:</h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            3.3.1. the provision of the Services does not
                            contradict the law or the obligations of the Owner
                            to third parties;{" "}
                        </p>
                        <p>
                            3.3.2. owns the Owner’s Resources, the right to use
                            them in accordance with the terms of this Offer, and
                            is also fully responsible for the content of the
                            Owner’s Resources.{" "}
                        </p>
                    </div>
                    <h3>
                        3.5. The Owner undertakes to install and ensure the
                        functioning of the Advertising Code and/or Software on
                        the Owner’s Resources and provide the possibility of
                        automatic placement of Advertising Materials by the
                        Company in accordance with the conditions set forth in
                        this Offer.
                    </h3>
                    <h3>
                        3.6. The Owner undertakes to ensure that the technical
                        requirements for installation and configuration of the
                        Advertising Code and/or the Software and the
                        requirements for Advertising Spaces on which Advertising
                        Materials are displayed are met. At the same time, the
                        Company is not responsible for the uninterrupted
                        operation of the Software and/or the Code.
                    </h3>
                    <h3>
                        3.7. The Company provides the Owner with the opportunity
                        to get acquainted with the statistics data in relation
                        to the Advertising Materials posted on the Owner’s
                        Resources through the Affiliate Interface, while these
                        statistics are recognized as Confidential Information;
                        access to the Affiliate interface is carried out using
                        the unique login and password of the Owner; The owner is
                        solely responsible for maintaining the confidentiality
                        of registration data (login and password). All actions
                        performed using the login and password of the Owner
                        through the Affiliate interface are recognized as
                        performed by the Owner. The Owner is solely responsible
                        to a third party for all actions performed using the
                        Owner’s login and password. The Company is not
                        responsible for access or unauthorized access to the
                        Affiliate interface by a third party, as well as for
                        violation of the performance of the Affiliate interface.
                    </h3>
                    <h3>
                        3.8. The Parties acknowledge that for the purposes of
                        this Offer, including to confirm the list and period of
                        the Services provision, only Statistics Data should be
                        used. Once every six months or at the written request of
                        the Owner, the Statistics Data of the Affiliate
                        interface is reconciled with the Statistics Data from
                        the Personal Account of each Advertising Provider, by
                        demonstrating the Statistics Data from the Personal
                        Account of each provider and comparing them with the
                        Statistics Data in the Affiliate interface in the
                        face-to-face presence of an authorized representative of
                        the Owner or using video communication as agreed by the
                        Parties. If the discrepancies towards the Owner exceed
                        1.5% (one and a half percent), a certificate on
                        completion is drawn up for the identified discrepancies
                        towards the Owner and paid by the Company. The service
                        is considered to be provided to the Company if as a
                        result of the functioning of the Advertising Code, the
                        Advertising was displayed on the Internet resource. At
                        the same time, the Parties agreed that the Statistics
                        Data from the Affiliate Interface on the volume of
                        services rendered (i.e. the facts of Advertisements
                        placement on the Resources) are reliable and are subject
                        to reflection in the Certificates on Services Rendered.
                    </h3>
                    <h3>
                        3.9. The Parties acknowledge that for the purposes of
                        the Offer, including when calculating the Owner’s
                        remuneration, any unfairly reproduced (automatically or
                        manually) clicks and displays are not taken into
                        account, all creating methods of which are incorrect,
                        dishonest, strictly prohibited.
                    </h3>
                    <h3>
                        3.10. The Company has the right to refuse the Owner to
                        fulfill obligations in cases where the Owner provided
                        false information and/or provided incomplete information
                        when filling out the registration form in accordance
                        with clause 3.2 of the Offer; Owner’s resources do not
                        meet the requirements of the Offer.
                    </h3>
                    <h3>
                        3.11. The Company has the right to check the good faith
                        of the Owner and refuse to conclude the Agreement
                        without disclosing the reasons for refusal.
                    </h3>
                </div>

                <h2 className="text-[20px] leading-[28px]">
                    4. Rights and obligations of the parties
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>4.1. The Company is obliged to:</h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            4.1.1. Make payment to the Owner on the terms, in
                            accordance with the provisions of this Offer.
                        </p>
                        <p>
                            4.1.2. Immediately inform the Owner of any
                            impediments and difficulties that interfere with the
                            execution of this Offer.
                        </p>
                        <p>
                            4.1.3. At the request of the Owner, within 2 hours
                            (between 09.00 and 21.00 GMT+6 time) to block
                            unwanted domains of Advertisers.
                        </p>
                        <p>
                            4.1.4. Train the Owner’s representatives in
                            effective methods of managing and placing the
                            Owner’s Advertisement.
                        </p>
                        <p>
                            4.1.5. Provide comprehensive technical and
                            consulting support to the Owner during the entire
                            period of the Agreement in order to fulfill the
                            terms of this agreement and its Annexes.
                        </p>
                    </div>
                    <h3>4.2. The Owner is obliged to:</h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            4.2.1. not violate the rules for displaying
                            Advertisements imposed by Suppliers; which can be
                            found on the Suppliers Resources.
                        </p>
                        <p>
                            4.2.2. Immediately notify the Company of any outages
                            in the operation of the Advertising Code, the
                            Owner’s Resources in the event that such outages and
                            errors may affect the proper performance of the
                            obligations by the Owner.
                        </p>
                        <p>
                            4.2.3. Immediately inform the Company in writing
                            about any impediments and difficulties that
                            interfere with the fulfillment of its obligations.
                        </p>
                        <p>
                            4.2.4. Comply with the requirements of the
                            applicable current legislation of the Republic of
                            Singapore and the territorial space where the
                            services will be provided.
                        </p>
                        <p>
                            4.2.5. Be solely responsible for providing the
                            Company with reliable/up-to-date payment details.
                        </p>
                    </div>
                    <h3>4.3. The parties have the right to:</h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            4.3.1 At any time, refuse to fulfill obligations
                            under this Offer with prior written notification to
                            the other Party 30 (thirty) calendar days in
                            advance, and in terms of mutual settlements - until
                            they are fully fulfilled.
                        </p>
                        <p>
                            4.3.2. It is permissible to mention joint
                            cooperation without obtaining the written consent of
                            the other Party only in presentations, marketing and
                            other promotional materials (as part of the partner
                            portfolio).
                        </p>
                    </div>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    5. Terms of remuneration and payment procedure
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        5.1. For the Services rendered, the Company pays the
                        Owner a remuneration, the amount of which is separately
                        agreed upon by the Parties and indicated in the Owner’s
                        personal account. All taxes, duties, other fees and
                        obligatory payments stipulated by the legislation
                        applicable to the Owner are paid independently by the
                        Owner at his expense.
                    </h3>
                    <h3>
                        5.2. The Owner’s remuneration includes all taxes,
                        duties, other fees and obligatory payments, in
                        accordance with the current tax legislation of the
                        Republic of Singapore.
                    </h3>
                    <h3>
                        5.3. All mutual settlements within the framework of this
                        Offer are carried out in the currency - US dollars
                        (USD).
                    </h3>
                    <h3>
                        5.4. In the Affiliate interface of the Company, on 3-5
                        (three to five) business days after the reporting
                        period, a report with Statistical Data is generated, on
                        the basis of which the Owner monthly provides the
                        Company with an invoice for payment, which records the
                        fact of the services provision in the reporting period
                        and their cost.
                    </h3>
                    <h3>
                        5.5. The Company pays remuneration within 30 (thirty)
                        banking days from the issuing date of an invoice for
                        payment (invoice) by the Owner; If the amount of the
                        Owner’s remuneration does not reach the established
                        threshold value, then the payment of remuneration is
                        carried out in the following periods, at the end of
                        which the threshold value will be reached, the
                        conditions for the size of the threshold value for the
                        Services provided are agreed and set individually.
                    </h3>
                    <h3>
                        5.6. Payment is made to the Owner on the payment service
                        chosen by him, specified in the registration form.
                    </h3>
                    <h3>
                        5.7. The Company is not responsible for the consequences
                        in cases where the Owner did not notify the Company in a
                        timely manner of any changes in payment details.
                    </h3>
                    <h3>
                        5.8. The Company pays only the commissions of its Bank
                        and is not responsible for the payment of commissions of
                        international and/or correspondent banks outside the
                        territory of Singapore. The Company is not responsible
                        for paying commissions from the Owner’s banks when
                        crediting funds to the Owner’s payment details.
                    </h3>

                    <h3>
                        5.9. The obligation of the Company to pay remuneration
                        to the Owner is considered fulfilled from the moment the
                        funds are debited from the Company’s account.
                    </h3>
                    <h3>
                        5.10. The Company does not pay remuneration to the
                        Resource Owner earlier than the date in which the
                        Supplier will pay remuneration to the Company for the
                        reporting period for the remuneration of the Resource.
                        If, for reasons beyond the control of the Company, the
                        Supplier cannot pay the remuneration, the Company is
                        obliged to notify the Partner no later than within 10
                        banking days of the occurrence of such a case.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    6. Responsibility of the parties
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        6.1. All disputes arising through the fault of the
                        Company as a result of the placement of the
                        Advertisement, including claims of third parties whose
                        interests are violated through the fault of the Company
                        as a result of such placement, as well as cases
                        initiated by the courts or authorized state bodies
                        through the fault of the Company as a result of such
                        placement, are settled by and with the participation of
                        the Company and/or the Supplier only if the Owner
                        complies with the following conditions:
                    </h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            6.1.1. The Owner immediately, but in any case not
                            later than 2 (two) working days, notified the
                            Company in writing on the receipt by the Owner of
                            the relevant claim, judicial act, notice, summons,
                            as well as a document issued by the authorized state
                            body.
                        </p>
                        <p>
                            6.1.2. The Owner provided the Company with copies on
                            the relevant claim, judicial act, notice, subpoena,
                            document issued by the authorized state body, as
                            well as other information requested by the Company
                            and reasonably necessary for the Company to settle
                            the relevant claims or cases.
                        </p>
                        <p>
                            6.1.3. The grounds for filing a claim and/or
                            initiating a case did not arise as a result of the
                            actions / inactions of the Owner or arose for
                            reasons beyond the control of the Owner.
                        </p>
                    </div>
                    <h3>
                        6.2. The total amount of the Company’s liability under
                        this Offer, including the amount of fines and/or
                        recoverable damages on a claim or claims in relation to
                        the Agreement or its execution, is limited to 5% (five
                        percent) of the amount of the Owner’s remuneration for a
                        period of 1 (one) calendar month preceding the date on
                        which the relevant liability of the Company arises.
                    </h3>
                    <h3>
                        6.3. Under no circumstances shall either Party be liable
                        under the Agreement for direct or indirect losses and/or
                        lost profits by a Party or third parties, regardless of
                        the ability of the other Party to foresee the
                        possibility of such losses.
                    </h3>
                    <h3>
                        6.4. Advertisement placed on the Owner’s Resources must
                        comply with the requirements of the current legislation
                        of the Republic of Singapore and applicable law
                        throughout the territory where advertising is
                        distributed. In the event that, in the opinion of the
                        Owner, the placement of the Advertisement contradicts
                        the above requirements of the applicable and current
                        legislation, or has resulted or may result in the filing
                        of claims regarding the violation of the legal rights of
                        third parties, the Owner has the right to send the
                        Company a reasonable request to stop or suspend the
                        placement of such Advertisement.
                    </h3>
                    <h3>
                        6.5. If the Owner violates the terms of this Offer, the
                        Company has the right to oblige the Owner to pay a
                        penalty in the amount of 0.1% (zero point one tenth) for
                        each day of such violation, but not more than 10% (ten)
                        of the total amount payable. Untimely and/or improper
                        provision of documents for payment by the Owner releases
                        the Company from liability for late payment. If the
                        Owner does not promptly inform the Company about the
                        change in bank details, the Company shall not be liable
                        for late transfer of payment under the Agreement or
                        transfer of payment using the old bank details. The
                        owner independently bears all responsibility, all risks
                        and expenses associated with the transfer of payment to
                        the old bank details.
                    </h3>
                    <h3>
                        6.6. The Company is not responsible for any disputable
                        issues as a result of the placement of the Advertisement
                        that arose for reasons beyond the control of the
                        Company, and is also not responsible for the failure to
                        achieve certain goals of the Owner, including, but not
                        limited to, the amount of remuneration expected by the
                        Owner for the provision of services, the performance of
                        the Affiliate interface, Advertising code and/or
                        Internet resources, including the Owner’s Resources.
                    </h3>
                    <h3>
                        6.7. Payment of penalties does not relieve the Parties
                        from fulfilling their obligations under this Offer. The
                        accrual and presentation of penalties is a right, not an
                        obligation of the Parties, and if they were not
                        presented, then they are not subject to accrual and
                        payment.
                    </h3>
                    <h3>
                        6.8. The parties are not entitled to demand from each
                        other compensation for any losses, compensation for
                        expenses and lost profits in any form, unless otherwise
                        specified by the terms of this Offer.
                    </h3>
                    <h3>
                        6.9. In case of claims, complaints, fines or other
                        requirements from the side of state bodies or other
                        third parties against the Company in relation to the
                        Resources and/or the activities of the Owner, the Owner
                        undertakes to independently settle all issues in a
                        pre-trial order at his own expense, as well as reimburse
                        the Company in full for all expenses that may arise /
                        have arisen in connection with such requirements.
                    </h3>
                    <h3>
                        6.10. In case of violation by the Owner of its
                        obligations, guarantees, the Company has the right to
                        refuse from the obligations fulfillment unilaterally out
                        of court (terminate the Agreement), while the services
                        in respect of which the Company recognized such a
                        violation are considered not rendered by the Owner and
                        are not subject to payment by the Company. In this case,
                        the Agreement is considered terminated from the date the
                        Company sends a notice of the Agreement termination by
                        e-mail.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    7. Guarantees of the Parties
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        7.1. The Parties guarantee that they have all the proper
                        rights to enter into the Agreement. If it subsequently
                        turns out that one of the Parties did not have the right
                        to conclude the Agreement (Acceptance), the guilty Party
                        shall reimburse all losses incurred as a result of this.
                    </h3>
                    <h3>
                        7.2. The parties guarantee that they are taxpayers and
                        are solely responsible for tax payments and reporting,
                        in accordance with the laws of the Republic of
                        Singapore.
                    </h3>

                    <h3>
                        7.3. Except for the warranties expressly stated in the
                        text of this Offer, the Company does not provide other
                        express or implied warranties and expressly disclaims
                        warranties or conditions regarding non-violation of the
                        rights or expectations associated with the services, or
                        the achievement of certain goals of the Owner,
                        including, but not limited to, the amount of
                        remuneration expected by the Owner for the provision of
                        services, the performance of the Affiliate Interface,
                        the Advertising Code and/or Internet resources,
                        including the Owner’s Resources.
                    </h3>
                    <h3>
                        7.4. The Owner warrants and represents to the Company
                        that:
                    </h3>
                    <div className="ml-[24px] flex flex-col gap-[16px]">
                        <p>
                            7.4.1. he has the proper legal capacity and legal
                            capacity to enter into and perform the Agreement,
                            and the provision of services by him does not
                            contradict the laws of the Republic of Singapore or
                            the obligations of the Owner to third parties, and
                            that the services in no way constitute a violation
                            of the rights and legitimate interests of third
                            parties;
                        </p>
                        <p>
                            7.4.2. he legally owns the Resources and/or has the
                            right to use them in accordance with the terms of
                            the Offer, and is also fully responsible for the
                            content, code and/or any other information posted by
                            the Owner on the Resources;
                        </p>
                        <p>
                            7.4.3. The Owner’s resources during the entire term
                            of the Agreement will comply with the requirements
                            of this Offer;
                        </p>
                        <p>
                            7.4.4. he provided the Company with valid and
                            reliable information, including personal data and
                            other information;
                        </p>
                        <p>
                            7.4.5. he has fully read and understands the meaning
                            of the Offer and the rules of the Suppliers, and is
                            also responsible for the consequences of his actions
                            when concluding and fulfilling his obligations.
                        </p>

                        <p>
                            7.4.6. The placement or use by the Company of
                            cookies and/or the use of other means of tracking
                            user activity does not contradict the law governing
                            the relationship between the Owner and the Users, or
                            that the Owner has taken all the necessary actions
                            provided for by the applicable law to grant the
                            Company the right to set cookies and/or use other
                            means tracking user activity, as well as the use of
                            data obtained with their help.
                        </p>
                    </div>
                </div>
                <h2 className="text-[20px] leading-[28px]">8. Force Majeure</h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        8.1. The Parties are released from liability for partial
                        or complete failure to fulfill obligations under this
                        Offer, if this is a consequence of force majeure
                        circumstances, i.e. is of an emergency nature, which
                        arose after the Acceptance of the Offer, and does not
                        depend on the will of the parties. Force majeure means:
                        wars and hostilities, uprisings, epidemics, fires,
                        explosions, earthquakes, floods, accidents and other
                        actions of the forces of nature, legislative acts and
                        acts of public authorities of the Republic of Singapore
                        that affect the fulfillment of obligations, as well as
                        other circumstances beyond the will and control of the
                        parties. Any documentary evidence, including the
                        conclusions of other competent state bodies of the
                        Republic of Singapore, can serve as confirmation of the
                        force majeure occurrence.
                    </h3>
                    <h3>
                        8.2. In the event of force majeure circumstances, the
                        Parties are obliged to immediately notify each other
                        about this. Failure to comply with this requirement
                        deprives the Party of the right to invoke force majeure
                        circumstances for exemption from liability. The deadline
                        for the fulfillment of obligations, upon the occurrence
                        of force majeure circumstances, is postponed in
                        proportion to the duration of the force majeure and its
                        consequences. If force majeure circumstances last for
                        more than 1 (one) month in a row, either Party has the
                        right to terminate the Agreement by notifying the other
                        Party at least 10 (ten) calendar days before
                        termination, while the Parties make final mutual
                        settlements in advance.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">9. Validity Term</h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        9.1. This Offer becomes effective on the date specified
                        in the “Effective Date” column above and remains in
                        effect until canceled or replaced by the Company.
                    </h3>
                    <h3>
                        9.2. The Company reserves the right at any time, at its
                        discretion, to make changes and additions to the Offer
                        and/or cancel the Offer, including documents to which
                        the Offer has a reference. If changes are made to the
                        Offer by the Company, such changes shall enter into
                        force on the date of placement of the amended text of
                        the Offer, unless a different date of entry into force
                        of such changes is indicated during placement.
                    </h3>
                    <h3>
                        9.3. Either Party has the right to terminate this
                        Agreement by notifying the other Party in writing at
                        least 30 (thirty) calendar days before the expected date
                        of termination.
                    </h3>
                    <h3>
                        9.4. The Agreement may be terminated by mutual agreement
                        of the Parties or in cases provided for by the
                        legislation of the Republic of Singapore.
                    </h3>
                    <h3>
                        9.5. If the Owner violates the terms of this Offer, the
                        Company has the right to unilaterally terminate the
                        Agreement immediately by sending a written notice
                        (including by e-mail) to the Owner.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    10. Disputes and disagreements of the parties
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        10.1. The Parties undertake to settle all disputes and
                        disagreements that may arise between them from the
                        application or interpretation of the text of this Offer,
                        through direct bilateral negotiations.
                    </h3>
                    <h3>
                        10.2. If it is impossible to settle disagreements by
                        sending a claim, disputes are subject to judicial
                        settlement in accordance with the current legislation of
                        the Republic of Singapore.
                    </h3>
                    <h3>
                        10.3. All issues that are not regulated by the parties
                        in the text of this Offer are settled in accordance with
                        the current legislation of the Republic of Singapore.
                    </h3>
                </div>
                <h2 className="text-[20px] leading-[28px]">
                    11. Other conditions
                </h2>
                <div className="text-[16px] leading-[22px] text-[#BDBDBD] flex flex-col gap-[16px]">
                    <h3>
                        11.1. The conditions for concluding and executing the
                        Offer are governed by the laws of the Republic of
                        Singapore. All matters not foreseen or not fully
                        foreseen shall be governed by the substantive laws of
                        the Republic of Singapore. If the dispute between the
                        Owner and the Company is not settled during negotiations
                        between the Parties, it is subject to consideration
                        exclusively in the courts of general jurisdiction of the
                        Republic of Singapore.
                    </h3>
                    <h3>
                        11.2. The Parties agreed that the terms of the Offer and
                        other information that became known in connection with
                        the fulfillment of obligations are confidential
                        information (trade secret) and are not subject to
                        disclosure. The Parties undertake to maintain
                        confidentiality except in cases of obtaining financing
                        from the Banks or obtaining a written permission from
                        the other Party in accordance with the laws of the
                        Republic of Singapore. Also, an exception to this
                        paragraph are lawyers (attorneys), accountants and
                        financial specialists hired by the Company or the Owner,
                        as well as copyright, judicial, state and other similar
                        competent organizations involved in the consideration of
                        any issue related to this Offer. In case of
                        non-compliance with the requirement of this paragraph,
                        the guilty Party shall be liable in accordance with the
                        legislation of the Republic of Singapore.
                    </h3>
                    <h3>
                        11.3. The invalidity of one or more clauses of the Offer
                        does not entail the invalidity of the Offer as a whole.
                    </h3>
                </div>
            </div>
        </ComponentContainer>
    );
};

export default AgreementPage;
