import React from "react";
import TitleContainer from "../ui/TitleContainer/TitleContainer";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";
import { Flex, Text } from "@radix-ui/themes";
import reports1 from "../../assets/images/dashboard/reports1.webp";
import reports2 from "../../assets/images/dashboard/reports2.webp";
import reports3 from "../../assets/images/dashboard/reports3.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/zoom";

const Dashboard = () => {
    const title = "IncomeStream Dashboard";
    const label = "Dashboard";

    return (
        <ComponentContainer separator>
            <TitleContainer title={title} label={label} color="#FFFFFF" />
            <Flex className="min-h-[160px]">
                <Swiper
                    spaceBetween={24}
                    loop={true}
                    centeredSlides={true}
                    zoom={true}
                    autoplay={{
                        delay: 2500,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false,
                        reverseDirection: true,
                    }}
                    slidesPerView={"auto"}
                    modules={[Autoplay, Zoom]}
                >
                    <SwiperSlide className="max-h-[160px] max-w-[256px] md:max-h-[348px] md:max-w-[556.8px] lg:max-h-[348px] lg:max-w-[556.8px]">
                        <div className="swiper-zoom-container">
                            <img src={reports1} alt="dashboard-1" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="max-h-[160px] max-w-[256px] md:max-h-[348px] md:max-w-[556.8px] lg:max-h-[348px] lg:max-w-[556.8px]">
                        <div className="swiper-zoom-container">
                            <img src={reports2} alt="dashboard-2" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className=" max-h-[160px] max-w-[256px] md:max-h-[348px] md:max-w-[556.8px] lg:max-h-[348px] lg:max-w-[556.8px]">
                        <div className="swiper-zoom-container">
                            <img src={reports3} alt="dashboard-3" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Flex>

            <Text className="text-[16px] md:text-[32px] lg:text-[32px] text-[#BDBDBD]">
                We provide interface to track income on your website
            </Text>
        </ComponentContainer>
    );
};

export default Dashboard;
