import React from "react";
import ConnectWithUs from "../components/ConnectWithUs/ConnectWithUs";
import Worldwide from "../components/Worldwide/Worldwide";
import { useLocation } from "react-router-dom";

const ContactPage = () => {
    const currentPath = useLocation();
    return (
        <div
            className={`${
                currentPath.pathname === "/contact" ? "mt-[84px]" : ""
            }`}
        >
            <ConnectWithUs />
            <Worldwide />
        </div>
    );
};

export default ContactPage;
