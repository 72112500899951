import React, { useRef } from "react";

import Partners from "../components/Partners/Partners";
import Worldwide from "../components/Worldwide/Worldwide";
import Platforms from "../components/Platforms/Platforms";
import Hero from "../components/Hero/Hero";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import Products from "../components/Products/Products";
import Leadership from "../components/Leadership/Leadership";
import Dashboard from "../components/Dashboard/Dashboard";
import ConnectWithUs from "../components/ConnectWithUs/ConnectWithUs";
function HomePage() {
    const ref = useRef<null | HTMLDivElement>(null);
    const handleHeroClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <>
            <Hero handleHeroClick={handleHeroClick} />
            <Partners />
            <Worldwide />
            <Platforms />
            <HowItWorks />
            <Products />
            <Dashboard />
            <Leadership />
            <ConnectWithUs ref={ref} />
        </>
    );
}

export default HomePage;
