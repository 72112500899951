import React from "react";
import heroVideo from "../../assets/video/hero.webm";
import heroMov from "../../assets/video/tds-bid-hero.mov";
import placeholderImage from "../../assets/images/hero-image.webp";
import { Separator } from "@radix-ui/themes";
import ComponentContainer from "../ui/ComponentContainer/ComponentContainer";

const Hero = ({ handleHeroClick }: any) => {
    return (
        <ComponentContainer separator={false}>
            <div className="flex flex-col-reverse space-y-[24px] lg:flex-row md:flex-row items-center lg:justify-between lg:h-screen lg:pt-[0px] md:justify-between md:h-screen md:pt-[0px] ">
                <div className="flex-1 flex-col text-white space-y-[24px] lg:space-y-[48px] pt-[24px]">
                    <div>
                        <h1 className="text-[36px] md:text-[58px] font-light tracking-[-2px] leading-[48px] md:leading-18">
                            Boost your Ad revenue
                        </h1>
                        <h1 className="m-0 text-[36px] md:text-[58px] font-light tracking-[-2px]  md:leading-18 text-gray-400">
                            Lift up to 15-70%
                        </h1>
                    </div>
                    <p className="md:w-491">
                        We sell every ad impression on your site in an auction,
                        through Prebid, which drives additional demand from
                        leading ad exchanges straight to your ad server.
                    </p>
                    <button
                        onClick={handleHeroClick}
                        className="w-full h-[60px] md:w-1/2 rounded-[12px] bg-[#538DFE] hover:bg-blue-600"
                    >
                        <p className="text-[16px]">Start bidding</p>
                    </button>
                </div>
                <div className="flex-1 ">
                    <video
                        id="hero-video"
                        playsInline
                        autoPlay
                        muted
                        loop
                        className=" w-full mt-[88px] lg:mt-[0px] lg:w-full lg:max-w-full"
                        poster={placeholderImage}
                    >
                        <source src={heroMov} type="video/mp4; codecs=hvc1"/>
                        <source src={heroVideo} type="video/webm"/>
                        Sorry, your browser doesn't support this video.
                    </video>
                </div>
            </div>
            <Separator
                orientation="horizontal"
                size="4"
                className="bg-[#3E3C3E] mt-[48px]"
            />
        </ComponentContainer>
    );
};

export default Hero;
